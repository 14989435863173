import React, {useEffect, useRef, useState} from "react";
import Map, {GeoJSONSource, Layer, MapRef, Source} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {clusterLayer, unclusteredPointLayer} from "./layers";
import {getAccommodationMapList, getReferentialsList} from "../../services/Accommodation.service";
import {Loading} from "../shared/Loading.component";
import {NuvalaMapPopup} from "../map_popup/NuvolaMapPopup.component";
import {getGuestLocation, UserLoc, getEmptyLocation} from "../../services/Locale.service";
import {NuvalaMapLocation} from "./NuvalaMapLocation";
import {FacilityModel} from "../../models/Accommodation";
import Select, {OnChangeValue } from "react-select";
import { ReactSVG } from 'react-svg'
import arrowUpIcon from '../../assets/images/arrow-up.svg';
import DatePicker, {registerLocale} from "react-datepicker";
import it from "date-fns/locale/it";
import {NuvalaMapMultiPopup} from "../map_multi_popup/NuvalaMapMultiPopup.component";

const MAX_ZOOM = 10

export function NuvalaMap({handleScrollUp}: {handleScrollUp: (event: any) => void}) {
    const mapRef = useRef<MapRef>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [geojson, setGeojson] = useState();
    const [userLocation, setUserLocation] = useState<UserLoc>(getEmptyLocation());
    const [selectedAccommodation, setSelectedAccommodation] = useState<string>("");
    const [selectedAccommodations, setSelectedAccommodations] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<FacilityModel[]>([]);
    const [typesValues, setTypesValues] = useState<{ value: string, label: string }[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [guests, setGuests] = useState(0);
    const [hideFilters, setHideFilters] = useState(true);
    const [zoom, setZoom] =  useState(8); //useState(6);

    useEffect(() => {
        Promise.all([getAccommodationMapList(), getGuestLocation()]).then(res => {
            setGeojson(res[0].data);
            // Hack to show always maps open
            let user_loc = res[1]
            user_loc.geo.latitude = 41.18857
            user_loc.geo.longitude = 16.67497
            setUserLocation(user_loc);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        if (userLocation) {
            mapRef.current?.easeTo({
                center: [userLocation.geo.longitude, userLocation.geo.latitude],
                zoom: zoom,
                duration: 500
            });
        }
    }, [userLocation]);

    useEffect(() => {
        mapRef.current?.easeTo({
            zoom: zoom,
            duration: 500
        });
    }, [zoom]);

    useEffect(() => {
        fetchAccommodations(guests, startDate, endDate);
    }, [filterValues, typesValues]);

    function fetchAccommodations(guests: number, start?: Date | null, end?: Date | null) {
        getAccommodationMapList(filterValues, typesValues, start, end, guests).then(res => {
            setGeojson(res.data);
        }).catch(err => {
            console.log(err);
        }).finally(() => {

        });
    }

    const onClick = (event : any) => {
        if(mapRef && event && event.features) {
            const feature = event.features[0];
            const clusterId = feature.properties?.cluster_id;
            if(clusterId !== undefined) {
                const mapboxSource = mapRef.current?.getSource('nuvala') as GeoJSONSource;
                if (zoom >= MAX_ZOOM) {
                    mapboxSource.getClusterLeaves(clusterId, 10, 0, (error, features) => {
                        setHideFilters(true);
                        setSelectedAccommodations(features.map((f: any) => f.properties?.slug));
                    });
                }
                else {
                    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) return;
                        zoom = Math.min(zoom, MAX_ZOOM);
                        setZoom(zoom);
                        mapRef.current?.setCenter(feature.geometry.coordinates);
                        mapRef.current?.easeTo({
                            center: feature.geometry.coordinates,
                            zoom: zoom,
                            duration: 15000
                        });
                    });
                }
            } else {
                const slug = feature.properties?.slug;
                setHideFilters(true);
                setSelectedAccommodation(slug);
            }
        }
    };

    let hoveredStateId: any = null;

    const onMouseEnter = (event: any) => {
        if(mapRef && mapRef.current && event && event.features) {
            mapRef.current.getCanvas().style.cursor = 'pointer';
            if (hoveredStateId !== null) {
                mapRef.current.setFeatureState(
                    { source: 'nuvala', id: hoveredStateId },
                    { hover: false }
                );
            }
            hoveredStateId = event.features[0].id;
            mapRef.current.setFeatureState(
                { source: 'nuvala', id: hoveredStateId },
                { hover: true }
            );
        }
    }

    const onMouseLeave = (event: any) => {
        if(mapRef && mapRef.current && event && event.features) {
            mapRef.current.getCanvas().style.cursor = '';
            mapRef.current.setFeatureState(
                { source: 'nuvala', id: hoveredStateId },
                { hover: false }
            );
        }
    }

    const onChangeDates = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if(start !== null && end !== null) fetchAccommodations(guests, start, end);
    }

    const onChangeGuests = (g: number) => {
        let computedGuests = Math.max(g, 0);
        computedGuests = Math.min(computedGuests, 20);
        if(computedGuests !== guests) {
            setGuests(computedGuests);
            fetchAccommodations(computedGuests, startDate, endDate);
        }
    }

    if(isLoading) return <Loading/>

    return (
        <>
            <NuvalaMapPopup
                accommodation_slug={selectedAccommodation}
                userLocation={userLocation}
                setSelectedAccommodation={setSelectedAccommodation}
                startDateIn={startDate}
                endDateIn={endDate}
                guestsIn={guests}
            />
            <NuvalaMapMultiPopup
                accommodation_slugs={selectedAccommodations}
                setSelectedAccommodation={setSelectedAccommodation}
            />
            <NuvalaMapLocation 
                onChangeDates={onChangeDates}
                startDate={startDate}
                endDate={endDate}
                onChangeGuests={onChangeGuests}
                guests={guests}
            />
            <NuvalaMapUI {...{zoom, setZoom, handleScrollUp}} />
            <div id="map">
                {/* <NuvalaMapFilters hide={hideFilters}
                                  filterValues={filterValues} setFilterValues={setFilterValues}
                                  typesValues={typesValues} setTypesValues={setTypesValues}
                                  onChangeDates={onChangeDates}
                                  startDate={startDate}
                                  endDate={endDate}
                                /> */}
                <Map
                    initialViewState={{
                        latitude: userLocation.geo.latitude,
                        longitude: userLocation.geo.longitude,
                        zoom: zoom
                    }}
                    scrollZoom={false}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    ref={mapRef}
                    interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
                    mapStyle="mapbox://styles/s4fe/ckywt6eih004x14t8s00785eq"
                    mapboxAccessToken="pk.eyJ1IjoiczRmZSIsImEiOiJja3l3dmQ5c3EwY2U0Mm9xaXpnaG9hajlyIn0.NxFOzf9mkgI9AieFiLCXsA"
                >
                    <Source
                        id="nuvala"
                        type="geojson"
                        data={geojson}
                        cluster={true}
                        clusterMaxZoom={14}
                        clusterRadius={50}
                        generateId={true}
                        clusterProperties={{'nuvala-fest': ['any', ['==', ['get', 'icon'], "blob-acc-fest"]]}}
                    >
                        <Layer {...clusterLayer} />
                        <Layer {...unclusteredPointLayer} />
                    </Source>
                </Map>
            </div>
        </>
    );
}

interface NuvalaMapFiltersProps {
    hide: boolean,
    filterValues: FacilityModel[],
    setFilterValues: (value: FacilityModel[]) => void,
    typesValues: { value: string, label: string }[],
    setTypesValues: (value: { value: string, label: string }[]) => void,
    startDate: Date | null,
    endDate: Date | null,
    onChangeDates: (dates: [Date | null, Date | null]) => void,
}

export function NuvalaMapFilters(props: NuvalaMapFiltersProps) {
    const [filters, setFilters] = useState<any[]>([]);
    const [types, setTypes] = useState<{ value: string, label: string }[]>([])
    const [isLoading, setIsLoading] = useState(true);
    const [isFlipped, setFlipped] = useState(false);
    const [isHidden, setHidden] = useState(false);

    registerLocale('it', it);

    useEffect(() => {
        getReferentialsList().then(res => {
            setFilters(res.data.facilities.groups);
            setTypes(res.data.types);
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {setHidden(props.hide)}, [props.hide]);

    function handleMultiSelectChange (e: OnChangeValue<any, any>, category: string) {
        // filter out input categories
        const previous_facilities: FacilityModel[] = props.filterValues.filter((f: FacilityModel) => f.category !== category)
        const new_cat = e.map((f: any) => ({
            "id": f.id,
            "name": f.value,
            "category": f.category,
            "group": f.group,
        }));
        props.setFilterValues([...previous_facilities, ...new_cat]);
    }

    function handleMultiSelectTypesChange (e: OnChangeValue<any, any>) {
        props.setTypesValues(e);
    }

    if (isLoading) return (
        <div className={"filter-box nuvala-modal p-2 text-center"} style={{width: "150px"}}>
            <div className="text-center">
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    )

    if (isHidden) return (
        <div className="filter-box">
            <div className="nuvala-yellow-modal-header">
                <h5 className="text-yellow">che nuvola vuoi?</h5>
            </div>
            <div className="nuvala-yellow-modal-body pt-4">
                <p className="text-nuvala-blue px-3">troviamo insieme la nuvala giusta
                    che porta alla tua evasione urbana!</p>
                <div className="nuvala-yellow-modal-button" onClick={() => setHidden(false)}>
                    <ReactSVG src={arrowUpIcon} />
                </div>
            </div>
        </div>
    )


    return (
        <div className={"filter-box flipcard" + (isFlipped ? " flipped" : "")}>
            <div className="face front">
                <div className="nuvala-yellow-modal-header">
                    <h5 className="text-yellow">che nuvola vuoi?</h5>
                </div>
                <div className="nuvala-yellow-modal-body">
                    <div>
                        <div className="row">
                            <div className="offset-1 col-11 px-4 mt-3">
                                <div>
                                    <p className="text-nuvala-blue my-1">date arrivo/partenza</p>
                                    <DatePicker
                                        className="form-control form-control-filters"
                                        placeholderText="Selezionare date arrivo e partenza"
                                        locale="it"
                                        dateFormat="dd-MM-yyyy"
                                        startDate={props.startDate}
                                        endDate={props.endDate}
                                        onChange={props.onChangeDates}
                                        focusSelectedMonth={true}
                                        minDate={new Date()}
                                        selectsRange
                                    />
                                </div>
                            </div>
                            <div className="offset-1 col-11 px-4 pb-2 mt-3">
                                <div>
                                    <p className="text-nuvala-blue my-1">tipologia strutture</p>
                                    <Select
                                        isMulti
                                        name="typology"
                                        options={types}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(e) => handleMultiSelectTypesChange(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            (filters.length > 0) && filters?.map((group, idx) => {
                                return (
                                    <div className="row" key={idx}>
                                        <div className="col-1 align-self-center">
                                            <div className="groups-vertical-text">{group.name}</div>
                                        </div>
                                        <div className="col-11 px-4 pb-2">
                                            {
                                                group.categories?.map((category: any, idx: number) => {
                                                    let fc: FacilityModel[] = props.filterValues.filter((f: FacilityModel) => f.category === category.name);
                                                    return (
                                                        <div className="py-2" key={idx}>
                                                            <p className="text-nuvala-blue my-1">{category.name}</p>
                                                            <Select
                                                                defaultValue={ fc.map((f: FacilityModel) => (
                                                                    {"label": f.name, "value": f.name, "category": f.category, "group": f.group, "id": f.id}
                                                                ))}
                                                                isMulti
                                                                name={category.name}
                                                                options={category.facilities.map((f: FacilityModel) => (
                                                                    {"label": f.name, "value": f.name, "category": f.category, "group": f.group, "id": f.id}
                                                                ))}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                onChange={(e) => handleMultiSelectChange(e, category.name)}
                                                            />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                        <div className="nuvala-yellow-modal-button" style={{padding: '10px'}} onClick={() => setHidden(true)}>
                            <ReactSVG style={{transform: 'rotate(180deg)'}} src={arrowUpIcon} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="face back">
                <div className="nuvala-yellow-modal-header">
                    <p className="text-yellow">che nuvola vuoi?</p>
                </div>
                <div className="nuvala-yellow-modal-body">
                    <a onClick={(event => setFlipped(false))}>
                        <h3 className="text-nuvala-blue mb-4">Impatto ambientale</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
                        <small>Torna ai filtri</small>
                        <br />
                        <small>&lt;----</small>
                    </a>
                </div>
            </div>
        </div>
    )
}

interface NuvalaMapUIProps {
    zoom: number,
    setZoom: (value: number) => void,
    handleScrollUp: (event: any) => void
}

export function NuvalaMapUI({zoom, setZoom, handleScrollUp}: NuvalaMapUIProps) {
    useEffect(() => {

    }, []);

    return (
        <ul className="list-group map-ui">
            <li className="list-group-item text-center" onClick={handleScrollUp}>↑</li>
            <li className="list-group-item text-center" onClick={(e) => zoom < 18 && setZoom(zoom + 1)}>+</li>
            <li className="list-group-item text-center" onClick={(e) => zoom > 1 && setZoom(zoom - 1)}>-</li>
        </ul>
    )
}